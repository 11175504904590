import 'intersection-observer';

export class AnimateObserver {

    constructor ( target = '.js-animate', options ) {
        this.target = target;
        this.options = {
            root: null,
            threshold: [
                0.05,
                0.1,
                0.2,
                0.3,
                0.4,
                0.5,
                0.6,
                0.7,
                0.8,
                0.9,
                0.95,
            ],
            class: 'js-animate',
            ...options
        }
    }

    init () {
        this.$elements = Array.from( document.querySelectorAll( this.target ) );
        if ( this.$elements.length > 0 ) {
            let time;
            if ( !time ) {
                time = 0;
            }
            this.observer = new IntersectionObserver( ( changes ) => {
                let count = 0;
                changes.forEach( change => {
                    if ( change.isIntersecting ) {
                        if(time === Math.floor(change.time/100)*100) {
                            count++;
                        } else {
                            count = 0;
                        }
                        time = Math.floor(change.time/100)*100;
                        if ( change.target.classList.contains( `${this.options.class}--paused` ) ) {
                            change.target.classList.remove( `${this.options.class}--paused` )
                            change.target.classList.add( `${this.options.class}--${count}` );
                            count++;
                        }
                    }
                } )
            }, this.options );

            this.$elements.forEach( $elem => {
                this.observer.observe( $elem );
            } )
        }
    }

    destroy() {
        this.observer.disconnect()
    }
}