import './vendor/modernizr';
import { AnimateObserver } from './lib/animate-observer';
 
// Import images as static assets for Vite
import.meta.glob([
    '../images/**',
]);

const animate = new AnimateObserver();
animate.init();

if ( document.querySelector( '.js-carousel' ) ) {
    import('./lib/carousel').then( module => {
        const carousel = new module.Carousel();
        carousel.init();
    });
}
